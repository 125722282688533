<template>
  <div>
    <b-form-group label="Title">
      <b-form-input v-model="title[title_lang]" />
      <LangOptions v-model="title_lang" :options="languages" />
    </b-form-group>
    <b-form-group label="Description">
      <b-form-textarea v-model="description[description_lang]" rows="3" />
      <LangOptions v-model="description_lang" :options="languages" />
    </b-form-group>
    <b-form-group>
      <template #label>
        User Role
        <InfoPopover class="ml-1" text="Lista degi ruoli utenti.." />
      </template>
      <TagsInput v-model="selected_role_list" :tagsList="role_list" />
    </b-form-group>
    <b-form-group>
      <template #label>
        Caricamento contenuti visivi
        <InfoPopover class="ml-1" text="Carica un file ecc." />
      </template>

      <b-form-file
        class="drop-file"
        v-model="files"
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/png, image/gif"
        multiple
      >
        <template #placeholder>
          <div class="my-4 text-center">
            <p class="h1 mb-1">
              <b-icon-cloud-arrow-up-fill />
            </p>
            <p>
              Choose a file or drop it here <br />
              (.jpg, .png, .pdf, .gif)
            </p>
          </div>
        </template>
        <template slot="file-name">
          <div class="my-4 text-center">
            <p class="h1 mb-1">
              <b-icon-cloud-arrow-up-fill />
            </p>
            <p>
              Choose a file or drop it here <br />
              (.jpg, .png, .pdf, .gif)
            </p>
          </div>
        </template>
      </b-form-file>
      <div class="mt-2">
        <span class="mr-2"><strong>Elements to upload:</strong></span>
        <span v-if="files.length">
          <span class="mr-2" v-for="file in files" :key="file">{{
            file.name
          }}</span>
        </span>
        <span v-else>No files selected</span>
      </div>
    </b-form-group>
    <div class="mt-5 mb-5">
      <b-button
        @click="saveItem"
        squared
        variant="primary"
        size="lg"
        class="big-button"
        >Confirm</b-button
      >
    </div>
    <div class="mt-5 mb-5"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      title: {},
      description: {},
      immagine: "",
      numero_foto: "",
      title_lang: null,
      description_lang: null,
      selected_role_list: [],
      role_list: [{ text: "ciao", value: 0 }],
      files: [],
    };
  },
  created() {
    this.title_lang = this.default_lang.value;
    this.description_lang = this.default_lang.value;
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
  },
};
</script>