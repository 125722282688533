<template>
  <div>
    <b-form-group label="Title" label-for="title">
      <b-form-input v-model="title" />
      <b-form-radio-group
        v-model="title_lang"
        :options="lang_options"
        button-variant="secondary"
        size="sm"
        name="radio-btn"
        buttons
        class="sub-form-buttons"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group label="Description" label-for="description-en">
      <b-form-textarea v-model="description" rows="3" />
      <b-form-radio-group
        v-model="description_lang"
        :options="lang_options"
        button-variant="secondary"
        size="sm"
        name="radio-btn"
        buttons
        class="sub-form-buttons"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group label-for="role_list">
      <template #label>
        Role List
        <InfoPopover
          class="ml-1"
          text="Lista dei ruoli etc. Lorem ipsum dolor sit amet"
        />
      </template>
      <b-form-tags v-model="role_list" placeholder=""></b-form-tags>
    </b-form-group>
    <b-form-group label="Inizio" label-for="start">
      <b-form-datepicker v-model="start" placeholder="-/-/-" />
    </b-form-group>
    <b-form-group label="Fine" label-for="end">
      <b-form-datepicker v-model="end" placeholder="-/-/-" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
  },
  data() {
    return {
      titolo: "",
      descrizione: "",
      utenti: "",
      immagine: "",
      numero_foto: "",
    };
  },
};
</script>